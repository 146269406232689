import React, { useCallback, useEffect, useState } from 'react';
import { localStateReducer } from '../../../utils/common';
import { mergeMessageIntoData } from './wsMessageMerge';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { ITDTKData, TDTKQuery, TDTKVars } from '../../../apollo/types';
import type { ServerError, WatchQueryFetchPolicy, FetchMoreQueryOptions, ApolloError } from '@apollo/client';
import { useSocketContext } from './SocketProvider';
import { useStateDispatch } from '../../../context/AppContext';
import ErrorPage from '../../nonDigital/error/ErrorPage';
import has from 'lodash/has';
import Spinner from '../../ui/loading/SpinnerWrapper';

type ChildrenProps<T extends ITDTKData> = (
  wsData: T | undefined,
  refetch: () => void,
  fetchMore: <TFetchData = T, TFetchVars = TDTKVars>(
    fetchMoreOptions: FetchMoreQueryOptions<TFetchVars, TFetchData>
  ) => void
) => React.ReactNode;

enum ErrorType {
  PAGINATED_STUDENTS = 'paginatedStudents',
}

function QueryComponent<T extends ITDTKData>({
  children,
  fetchPolicy = 'network-only',
  pollInterval = 0,
  query,
  skip = false,
  generateFetchMoreOptions,
  showError = true,
  renderImmediately = false,
}: {
  children: ChildrenProps<T>;
  fetchPolicy?: WatchQueryFetchPolicy;
  pollInterval?: number;
  query: TDTKQuery<T>;
  skip?: boolean;
  generateFetchMoreOptions?: (data: T | undefined) => void;
  showError?: boolean;
  renderImmediately?: boolean;
}) {
  // Global App state.
  const dispatch = useStateDispatch();
  const { lastJsonMessage } = useSocketContext();

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    skip,
  });

  const options: {
    fetchPolicy: WatchQueryFetchPolicy;
    pollInterval: number;
    variables?: TDTKVars;
  } = {
    fetchPolicy,
    pollInterval,
  };
  if ('variables' in query) {
    options.variables = query.variables;
  }
  const [getData, { loading, error, data, refetch, fetchMore }] = useLazyQuery<T, TDTKVars>(
    query.specification,
    options
  );

  const [wsData, setWsData] = useState<T>();

  const client = useApolloClient();

  // Skip on re-renders.
  useEffect(() => {
    if (!data && !loading && !localState.skip) {
      getData();
      setLocalState({
        skip: true,
      });
    }

    query.resolvedData = data;
    const { hasUpdated, updatedQuery } = mergeMessageIntoData<T>(query, lastJsonMessage);

    if (hasUpdated) {
      // if our replacement apolloObject is relevant to websocket updates, merge it in
      client.writeQuery({
        query: query.specification,
        data: updatedQuery.resolvedData,
      });
    }
    setWsData(query.resolvedData as T);
  }, [client, getData, lastJsonMessage, data, localState.skip, loading, query]);

  /**
   * Mutate the globalData with relevant errors if necessary
   * @param globalData contains the entire data array of all merged data
   * @param dataSnippet contains the snippet of data that we recently received
   * @param error relevan error dealing with the dataSnippet
   * Note: we need the globalData to mutate it with relevant errors.
   */
  const errorHandler = (globalData: any, dataSnippet: any, error: Partial<ApolloError>) => {
    const gqlErrorExists = !!(
      error &&
      error.graphQLErrors &&
      error.graphQLErrors[0] &&
      error.graphQLErrors[0].path &&
      error.graphQLErrors[0].path[0]
    );

    const isGqlTypeError = (error: any) => {
      if (error && has(error, 'graphQLErrors') && error.graphQLErrors && error.graphQLErrors.length > 0) {
        return error.graphQLErrors.every((gqlError: { message: string }) => {
          const isGqlTypeError =
            gqlError.message.startsWith('Cannot return null') ||
            gqlError.message.startsWith('Expected value of type') ||
            gqlError.message.startsWith('Expected Iterable');

          return isGqlTypeError;
        });
      }
      return false;
    };

    const isGqlServerError = (error: any) => {
      if (error && has(error, 'graphQLErrors') && error.graphQLErrors && error.graphQLErrors.length > 0) {
        return error.graphQLErrors.every((gqlError: { message: string }) => gqlError.message === 'server_error');
      }
      return false;
    };

    /**
     * Default error handler for all errors that are not handled by the other cases.
     */
    const defaultErrorHandler = () => {
      console.error('Unhandled GraphQL Error!');
      console.error('Failed GQL', query);
      console.error('Error Object', error);
      dispatch({
        globalError: <ErrorPage errorCode='500' />,
      });
    };

    /**
     * Handle Schema Type Validation Errors for graphql
     */
    const handleGqlTypeError = (globalData: any, dataSnippet: any, error: Partial<ApolloError>) => {
      const errorType = error?.graphQLErrors?.[0]?.path?.[0];
      const indexErrMsgMap: { [key: number]: any } = {};

      switch (errorType) {
        case ErrorType.PAGINATED_STUDENTS:
          //only support paginated students for now.
          //err.path is an array of the path to the error. i.e. "paginatedStudents.students.0"
          //where path[2] is the index of the student in the paginatedStudents.students array
          //when dealing with GqlTypeErrors, these are Schema Validation Errors.
          //therefore we are always referencing specific student records in the data that failed Schema Validation
          error?.graphQLErrors
            ?.filter((err) => {
              return err.path && Array.isArray(err.path) && !isNaN(err.path?.[2]);
            })
            .map((err) => {
              const index = err.path?.[2];
              indexErrMsgMap[index] = {
                message: err.message,
                path: err.path,
              };
            });
          for (const index of Object.keys(indexErrMsgMap)) {
            console.error(
              'Validation Error for the following data',
              dataSnippet.paginatedStudents.students[index],
              ' with the following error: ',
              indexErrMsgMap[index]
            );
          }
          break;
        default:
          console.error('Unhandled GraphQL Type Error!');
          console.error('Failed GQL', query);
          console.error('Error Object', error.graphQLErrors);
          break;
      }
    };

    /**
     * Handle server_error from graphql
     */
    const handleGqlServiceError = (globalData: any, error: Partial<ApolloError>) => {
      const errorType = error?.graphQLErrors?.[0]?.path?.[0];

      switch (errorType) {
        case ErrorType.PAGINATED_STUDENTS:
          console.error('Failed GQL', query);
          console.error('Error Object', error);
          break;
        default:
          console.error('Unhandled GraphQL Server Error!');
          console.error('Failed GQL', query);
          console.error('Error Object', error.graphQLErrors);
          break;
      }
    };

    //proceed to handle the error
    if (showError) {
      if (has(error, 'networkError.statusCode') && has(error, 'networkError.result')) {
        const serverError = error?.networkError as ServerError;
        const resErrorCode = serverError?.result?.errors?.[0]?.code;
        const resStatusCode = serverError?.statusCode?.toString() || '500';
        if (resStatusCode >= '400' && resStatusCode <= '499') {
          // All 400 Errors
          if (resErrorCode === 'auth:missing_user_role') {
            console.error('auth:missing_user_role', resErrorCode);
            dispatch({ globalError: <ErrorPage errorCode={'auth:missing_user_record'} /> });
          } else if (resStatusCode === '403') {
            console.error('403', serverError.result);
            dispatch({ globalError: <ErrorPage errorCode='403' /> });
          } else if (resStatusCode === '429') {
            dispatch({ globalError: <ErrorPage errorCode='429' /> });
          } else {
            dispatch({ globalError: <ErrorPage errorCode='400' /> });
          }
        } else if (resErrorCode === 'auth:missing_auth_info') {
          console.error('auth:missing_auth_info', serverError.result.message);
          dispatch({ globalError: <ErrorPage errorCode='400' /> });
        } else if (resStatusCode >= '500' && resStatusCode <= '599') {
          // All 500 errors
          console.error('resStatusCode = ', resStatusCode);
          dispatch({ globalError: <ErrorPage errorCode='500' /> });
        }
      } else if (gqlErrorExists) {
        if (isGqlTypeError(error)) {
          handleGqlTypeError(globalData, dataSnippet, error);
        } else if (isGqlServerError(error)) {
          handleGqlServiceError(globalData, error);
        } else {
          defaultErrorHandler();
        }
      } else {
        defaultErrorHandler();
      }
    }
  };

  const fetchMoreCallBack = useCallback(async () => {
    const options = generateFetchMoreOptions?.(wsData);
    if (options) {
      await fetchMore(options).then((result) => {
        if (result.errors) {
          errorHandler(data, result.data, {
            graphQLErrors: result.errors,
          });
        }
      });
    }
  }, [wsData, fetchMore, generateFetchMoreOptions]);

  useEffect(() => {
    fetchMoreCallBack();
  }, [wsData, fetchMore, generateFetchMoreOptions]);

  if (loading && !renderImmediately) {
    return <Spinner />;
  }

  if (error) {
    //error is only ever set here if the first calls fails. Any fetchmore calls do not set error.
    //so data is the only relevant data snippet.
    errorHandler(data, data, error);
  }

  return children(wsData, refetch, fetchMore);
}

export default QueryComponent;
