import { useEffect, useState } from 'react';
import { EVENT_TYPES } from '../../../constants/event';
import { useStateValue } from '../../../context/AppContext';
import { IMultiStepperCMSData, INotificationsCMSData, IContentCMSData, IIRCMSData } from './types';
import { populateMultiStepData } from './cmsUtilties';
import { getCurrentDomain } from '../../../constants/cbEnv';
import axios from 'axios';
import { NO_CACHE_HEADERS } from '../../../constants/http';

export const useMultiStepperCMSData = () => {
  const { orgEvent } = useStateValue();
  const currentDomain = getCurrentDomain();
  const [cmsData, setCmsData] = useState<IMultiStepperCMSData | null>(null);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    async function getCMS() {
      const tdtkAsmtType = Object.entries(EVENT_TYPES).find(([, value]) => value === orgEvent.tdtkAsmtType)?.[0] || '';
      try {
        const multiStepData = await populateMultiStepData(tdtkAsmtType, orgEvent.asmtId);
        setCmsData(multiStepData);
      } catch (error) {
        console.error('Error fetching multi-stepper CMS data', error);
      } finally {
        setLoading(false);
      }
    }
    getCMS();
  }, [currentDomain, orgEvent.tdtkAsmtType]);
  return { cmsData, isLoading };
};

export const useNotificationsCMSData = () => {
  const { orgEvent } = useStateValue();
  const currentDomain = getCurrentDomain();
  const [cmsData, setCmsData] = useState<INotificationsCMSData | null>(null);
  useEffect(() => {
    async function getCMS() {
      const currentDomain = getCurrentDomain();
      const url = `${currentDomain}/cmsDirectory/Notifications/Event-${orgEvent.asmtEventId}.json`;
      try {
        const cmsResponse = await axios(url, { headers: NO_CACHE_HEADERS });
        const notifications = cmsResponse?.data;
        setCmsData(notifications);
      } catch (error) {
        console.error('Error fetching notifications CMS data', error);
      }
    }
    getCMS();
  }, [currentDomain, orgEvent.asmtEventId]);
  return cmsData;
};

export const useContentCMSData = (sectionName: string, cmsContentLoaded?: (content: any) => void) => {
  const { orgEvent } = useStateValue();
  const currentDomain = getCurrentDomain();
  const [cmsData, setCmsData] = useState<IContentCMSData | null>(null);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    async function getCMS() {
      const currentDomain = getCurrentDomain();
      const tdtkAsmtType = Object.entries(EVENT_TYPES).find(([, value]) => value === orgEvent.tdtkAsmtType)?.[0] || '';
      const url = `${currentDomain}/cmsDirectory/DIGITAL_${tdtkAsmtType}/Section-${sectionName}.json`;
      try {
        const cmsResponse = await axios(url, { headers: NO_CACHE_HEADERS });
        const cmsContent = cmsResponse?.data;
        cmsContentLoaded && cmsContentLoaded(cmsResponse?.data?.content);
        setCmsData(cmsContent);
      } catch (error) {
        console.error('Error fetching CMS content data', error);
      } finally {
        setLoading(false);
      }
    }
    getCMS();
  }, [cmsContentLoaded, currentDomain, orgEvent.tdtkAsmtType, sectionName]);
  return { cmsData, isLoading };
};

export const useIRCMSData = () => {
  const { orgEvent } = useStateValue();
  const currentDomain = getCurrentDomain();
  const [cmsData, setCmsData] = useState<IIRCMSData | null>(null);
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    async function getCMS() {
      const currentDomain = getCurrentDomain();
      const tdtkAsmtType = Object.entries(EVENT_TYPES).find(([, value]) => value === orgEvent.tdtkAsmtType)?.[0] || '';
      const url = `${currentDomain}/cmsDirectory/DIGITAL_${tdtkAsmtType}/IrTemplates/IrCategoryListTemplate.json`;
      try {
        const cmsResponse = await axios(url, { headers: NO_CACHE_HEADERS });
        setCmsData(cmsResponse?.data);
      } catch (error) {
        console.error('Error fetching CMS IR data', error);
      } finally {
        setLoading(false);
      }
    }
    getCMS();
  }, [currentDomain, orgEvent.tdtkAsmtType]);
  return { cmsData, isLoading };
};
