import React from 'react';
import get from 'lodash/get';
import GroupsInfo from './GroupsInfo';
import { GET_DIGITAL_STUDENTS_PAGINATED_ROSTER } from '../../../apollo/queries';
import QueryComponent from '../network/QueryComponent';
import { fetchMoreStudents } from '../../../utils/pagination';
import SpinnerWrapper from '../../ui/loading/SpinnerWrapper';

interface ContainerProps {
  description?: React.ReactElement[];
  showHeading1?: boolean;
  showHeading2?: boolean;
  usePopovers?: boolean;
  showFilterLinks?: boolean;
  stickyHeaders?: boolean;
  tableStyleLight?: boolean;
  tableStyleShadow?: boolean;
  tableStyleReverseStripes?: boolean;
  tableStyleBorderless?: boolean;
  forceDesktopMode?: boolean;
}

function Container({
  description = [],
  showHeading1 = true,
  showHeading2 = false,
  usePopovers = false,
  stickyHeaders = false,
  tableStyleLight = false,
  tableStyleShadow = false,
  tableStyleReverseStripes = false,
  tableStyleBorderless = false,
  showFilterLinks = true,
  forceDesktopMode = false,
}: ContainerProps) {
  return (
    <QueryComponent
      fetchPolicy='cache-only'
      query={{
        kind: 'GetDigitalStudentsPaginatedRoster',
        specification: GET_DIGITAL_STUDENTS_PAGINATED_ROSTER,
      }}
      generateFetchMoreOptions={fetchMoreStudents}
    >
      {(data) => {
        const students = (data?.paginatedStudents?.students || []).filter(Boolean);
        if (students.length) {
          return data?.paginatedStudents?.lastKey ? (
            <SpinnerWrapper />
          ) : (
            <GroupsInfo
              description={description}
              showHeading1={showHeading1}
              showHeading2={showHeading2}
              students={students}
              usePopovers={usePopovers}
              stickyHeaders={stickyHeaders}
              showFilterLinks={showFilterLinks}
              tableStyleLight={tableStyleLight}
              tableStyleShadow={tableStyleShadow}
              tableStyleReverseStripes={tableStyleReverseStripes}
              tableStyleBorderless={tableStyleBorderless}
              forceDesktopMode={forceDesktopMode}
            />
          );
        } else {
          return showHeading1 ? (
            <React.Fragment>
              <h1 className='tdtk-h1 mb-4'>Testing Groups</h1>
              <p>Student roster information is unavailable.</p>
            </React.Fragment>
          ) : null;
        }
      }}
    </QueryComponent>
  );
}

export default Container;
