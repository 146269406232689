import Messaging from '../../../context/Messaging';
import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from '@cb/apricot-react';
import { analyticsTracking } from '../../../utils/analytics';
import { useStateValue } from '../../../context/AppContext';
import './Login.scss';

function Login({ appId = 378 }) {
  // Global App state.
  const { user } = useStateValue();

  const currentDomain = window?.location?.origin || 'https://testday.collegeboard.org/';
  const currentPath = `${window?.location?.pathname ?? '/'}${window?.location?.search ?? ''}`;

  React.useEffect(() => {
    analyticsTracking(user.role, '', 'login');
  }, [user.role]);

  return (
    <React.Fragment>
      <div className='row row-eq-height mx-0 mt-4'>
        <div className='col-md-6'>
          <div className='box-card shadow border__gray p-4 h-100'>
            <div className='cb-wg-identity-card'>
              <div className='cb-wg-identity-card-container'>
                <Icon name='user' circular circleSize='64' decorative />
                <h2 className='tdtk-h2' id='sign-header'>
                  Sign in to your account to get started
                </h2>
                <a
                  href={`https://account.collegeboard.org/login/login?appId=${appId}&idp=EPL&skipEnroll=Y&DURL=${`${currentDomain}${currentPath}`}`}
                  className='cb-btn cb-btn-sm cb-btn-yellow mt-4'
                  id='login-continue-button'
                >
                  Continue
                  <span className='cb-sr-only'>to the CollegeBoard Professional Login page to sign in</span>
                </a>
              </div>
              <div className='mt-4 text-center'>
                <a
                  href='https://support.collegeboard.org/help-center/professional-account-help'
                  rel='noopener noreferrer'
                  target='_blank'
                  id='sign-help-link'
                >
                  Need help signing in?
                </a>
              </div>
            </div>
            <div className='cb-cta-card cb-login-module-cta mt-4 display-flex-col-content-center'>
              <Icon name='user-add' circular circleSize='64' decorative />
              <h3 className='tdtk-h3' id='create-account-header'>
                Don&rsquo;t have an account?
              </h3>
              <a
                href={`https://account.collegeboard.org/login/signUp?appId=${appId}&idp=EPL&skipEnroll=Y&DURL=${`${currentDomain}${currentPath}`}`}
                className='cb-btn cb-btn-sm cb-btn-yellow cb-xs-btn-fluid mt-4'
                id='create-account-button'
              >
                Create Account
              </a>
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='box-card shadow border__gray p-4 h-100'>
            <Messaging section='homeIntroHelpText' />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

Login.propTypes = {
  appId: PropTypes.number,
  location: PropTypes.object,
  testDate: PropTypes.string,
};

export default Login;
