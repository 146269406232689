import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@cb/apricot-react';
import { createCleanMarkup, toLocaleUTCDateString } from '../../../utils/common';
import get from 'lodash/get';
import { useStateValue } from '../../../context/AppContext';

function ClosureFormCompleted({ showFull = true }) {
  // Global App state.
  const { orgEvent } = useStateValue();

  // Date formatting.
  const makeupDateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const submittedDateOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  // Pull out closure event data if it exists.
  const eventStartDt = get(orgEvent, 'eventStartDt', '');
  const canSupportMakeup = get(orgEvent, 'closure.canSupportMakeup', 'n/a');
  const confirmationId = get(orgEvent, 'closure.confirmationId', '');
  const created = new Date(parseInt(get(orgEvent, 'closure.created', '0'), 10)).toLocaleDateString(
    undefined,
    submittedDateOptions
  );
  const makeup = (orgEvent?.closure?.makeupDate || '').split('-');
  const nextSteps = get(orgEvent, 'closure.nextSteps', '');

  // NOTE: currently we have to remove a ' - Sunday' suffix on the title string
  // since the backend is appending that to the title for Sunday site closures
  // TO DO : temporary need to removed once the new DB changes take place
  const reason = orgEvent?.closure?.reason?.replace(/[-\s]*?Sunday$/i, '') || 'n/a';

  // Convert the string to a date object so we can format for locals.
  const makeupDate =
    makeup.length === 3
      ? new Date(
          parseInt(makeup[0], 10),
          parseInt(makeup[1], 10) - 1,
          parseInt(makeup[2], 10),
          0,
          0,
          0,
          0
        ).toLocaleDateString(undefined, makeupDateOptions)
      : null;

  // Auto-redirect them to the submission form if they don't have an ID for some reason.
  if (!confirmationId) {
    window.location.href = '/test-center-closure/add';
  }

  return (
    <React.Fragment>
      {showFull ? (
        <div
          className='m-2 box-card shadow border__rounded-full border__gray formatted-content'
          style={{ padding: '3rem' }}
        >
          <h1 className='tdtk-h1 mb-4' id='closure-confirmation-h1'>
            Test Center Closed
          </h1>
          <div className='box-card shadow border__rounded-full background-color--gray border__corporate'>
            <h2 className='tdtk-h2' id='closure-confirmation-h2-confirmation'>
              Confirmation
            </h2>
            <p>
              You closed your test center for the SAT administration
              {eventStartDt
                ? ` on ${toLocaleUTCDateString(new Date(eventStartDt), {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric',
                  })}`
                : null}
              .
            </p>
            <p id='closure-confirmation-p-created'>
              <strong>Date submitted:</strong> {created}
            </p>
            <p id='closure-confirmation-p-confirmation-code'>
              <strong>Confirmation code:</strong> {confirmationId}
            </p>
            <p id='closure-confirmation-p-reason'>
              <strong>Reason for closing:</strong> {reason}
            </p>
            {makeupDate ? (
              <p id='closure-confirmation-p-makeup'>
                <strong>Makeup test availability:</strong>{' '}
                {canSupportMakeup && canSupportMakeup.toLowerCase() === 'yes'
                  ? `Your test center can offer a makeup test on ${makeupDate}.`
                  : canSupportMakeup && canSupportMakeup.toLowerCase() === 'no'
                  ? `Your test center cannot offer a makeup test on ${makeupDate}.`
                  : `Your test center might be able to offer a makeup test on ${makeupDate}.`}
              </p>
            ) : null}
          </div>
          <h2 className='tdtk-h2' id='closure-confirmation-h2-critical-next-steps'>
            Critical Next Steps and Information
          </h2>
          <section id='closure-confirmation-section-next-steps'>
            {
              // Use the "Next Steps" WYSIWYG text if it exists, otherwise use default.
              nextSteps ? (
                [<div key='closure-confirmation-next-steps' dangerouslySetInnerHTML={createCleanMarkup(nextSteps)} />]
              ) : (
                <React.Fragment>
                  <h3 className='tdtk-h3'>Your Next Steps</h3>
                  <ul>
                    <li>Notify local media (as you do for emergency school closings).</li>
                    <li>Post the closure on your school website.</li>
                    <li>Have someone on-site in case students show up. If that’s not possible, put up a sign.</li>
                    <li>Return all test materials immediately or store them securely until you can return them.</li>
                    <li>
                      If you’re not sure you can offer a makeup test, find out if it’s possible and contact TAS at{' '}
                      <a href='tel:+18665026384'>866-502-6384</a> or{' '}
                      <a href='mailto:tas@ets.org?subject=Test%20Center%20Closure'>tas@ets.org</a> on the Monday or
                      Tuesday after test day.
                    </li>
                    <li>
                      If you need to close for the Sunday administration or another Saturday test date, contact TAS at{' '}
                      <a href='tel:+18665026384'>866-502-6384</a> or{' '}
                      <a href='mailto:tas@ets.org?subject=Test%20Center%20Closure'>tas@ets.org</a>.
                    </li>
                  </ul>
                  <h3 className='tdtk-h3' id='closure-confirmation-h3-our-next-steps'>
                    Our Next Steps
                  </h3>
                  <ul>
                    <li>
                      If you reported your closure before 8 p.m. ET on the Friday before test day, we’ll email students
                      telling them not to report to your test center.
                    </li>
                    <li>
                      We’ll post test center closures on{' '}
                      <a
                        href='https://collegereadiness.collegeboard.org/sat/register/test-center-closings'
                        rel='noopener noreferrer'
                        target='_blank'
                        title='sat.org/test-center-closings'
                      >
                        sat.org/test-center-closings
                      </a>{' '}
                      as quickly as possible.
                    </li>
                    <li>We’ll contact all students about makeup tests, cancellations, and refunds.</li>
                  </ul>
                </React.Fragment>
              )
            }
          </section>
          <p>
            <Link id='closure-confirmation-link-homepage' className='cb-btn cb-btn-sm cb-btn-yellow' to='/'>
              Return to Homepage
            </Link>
          </p>
        </div>
      ) : (
        <React.Fragment>
          <p role='presentation' className='text-center mb-4'>
            <Icon name='megaphone' color='black1' size='32' />
          </p>
          <h3 className='tdtk-h3 text-center mb-4'>Test Center Closed</h3>
          <p className='mb-4'>
            You closed your test center for the SAT administration
            {eventStartDt
              ? ` on ${toLocaleUTCDateString(new Date(eventStartDt), {
                  weekday: 'long',
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                })}`
              : null}
            .
          </p>
          <p className='mb-4'>
            <strong>Date submitted:</strong>
            <br />
            <span id='closure-confirmation-span-created'>{created}</span>
          </p>
          <p className='mb-4'>
            <strong>Confirmation code:</strong>
            <br />
            <span id='closure-confirmation-span-code'>{confirmationId}</span>
          </p>
          <p className='mb-4' id='closure-confirmation-p-reason'>
            <strong>Reason for closing:</strong> {reason}
          </p>
          {makeupDate ? (
            <p className='mb-4' id='closure-confirmation-p-makeup'>
              <strong>Makeup test availability:</strong>{' '}
              {canSupportMakeup && canSupportMakeup.toLowerCase() === 'yes'
                ? `Your test center can offer a makeup test on ${makeupDate}.`
                : canSupportMakeup && canSupportMakeup.toLowerCase() === 'no'
                ? `Your test center cannot offer a makeup test on ${makeupDate}.`
                : `Your test center might be able to offer a makeup test on ${makeupDate}.`}
            </p>
          ) : null}
          <p className='mb-4'>
            <Link id='closure-confirmation-link-what-to-do-next' to='/help#how-to-close'>
              Find out what to do next.
            </Link>
          </p>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

ClosureFormCompleted.propTypes = {
  showFull: PropTypes.bool,
};

export default ClosureFormCompleted;
