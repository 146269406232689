import React from 'react';
import { CardWithTitle } from '../../../../ui/card/CardWithTitle';
import { AccordionList, AccordionItem, YellowButton, FileUpload, Icon } from '@cb/apricot-react';
import './ChooseFile.scss';

interface CardContentRowProps {
  title: string;
  content: any;
  contentId: string;
  headerId: string;
  open?: boolean;
}

const CardContentRow = ({ items }: { items: CardContentRowProps[] }) => {
  return (
    <AccordionList className='accordion-wrapper ' title={''}>
      {items.map(({ title, content, contentId, headerId, open = false }) => {
        return (
          <AccordionItem
            classNameHeader='accordion-item-header'
            headerId={headerId}
            open={open}
            key={title}
            panelId={`accordion-item-${contentId}`}
            title={title}
          >
            {content}
          </AccordionItem>
        );
      })}
    </AccordionList>
  );
};

const RequirementsContent = () => {
  return (
    <ul>
      <li>The file type must be CSV.</li>
      <li>The file size must be less than 2 MB.</li>
      <li>The file name can only include letters, numbers, spaces, parentheses, hyphens, and underscores.</li>
      <li>
        Each row must include data for all 4 columns: first (given) name, last (family) name, email, and phone number.
      </li>
      <li>Staff names can only include letters, numbers, spaces, and basic punctuation.</li>
      <li>Each staff member must have a unique email.</li>
      <li>Phone numbers can only include numbers, spaces, plus signs, hyphens, and parentheses. </li>
      <li>
        The simplest way to add new staff with international phone numbers is to select <strong>Add to table</strong>{' '}
        from the <strong>Add Staff</strong> dropdown menu.
      </li>
    </ul>
  );
};

const TipsContent = () => {
  return (
    <ul>
      <li>Copy data into the template you downloaded instead of creating your own file. </li>
      <li>Include new staff only. To add returning staff to a new test administration, select them from your list.</li>
      <li>Use a phone number and email your staff can access quickly.</li>
      <li>
        The simplest way to add new staff with international phone numbers is to select <strong>Add to table</strong>{' '}
        from the <strong>Add Staff</strong> dropdown menu.
      </li>
      <li>
        Learn more about adding staff in the{' '}
        <a
          className='cb-link-blue'
          href='https://bluebook.collegeboard.org/test-admin/help'
          target='_blank'
          rel='noreferrer noopener'
        >
          Test Administration Help Center.
        </a>
      </li>
    </ul>
  );
};

const generateCSVTemplate = () => {
  const fileType = 'data:text/csv;charset=utf-8,';
  const fileContents = 'First Name,Last Name,Email,Phone';
  const encodedUri = encodeURI(fileType + fileContents);
  return encodedUri;
};

const DownloadCsvTitle = () => {
  const csvTemplate = generateCSVTemplate();
  return (
    <div className='d-flex flex-row align-items-center accordion-header w-100 justify-content-space-between'>
      {/* Using list for now because list-edit isn't working */}
      <div className='d-flex flex-row align-items-center'>
        <Icon name='list' className='p-2' />
        <b>Download the template and add staff data.</b>
      </div>
      <div className='d-flex flex-row align-items-center'>
        <a href={csvTemplate} target='_blank' rel='noreferrer' download={'Staff-Upload-Template.csv'}>
          <YellowButton
            small
            className='w-100'
            data-automation={'download-csv-btn'}
            id={'download-csv-btn'}
            onClick={() => {}}
            type='button'
          >
            Download CSV Template
            <Icon name='download' />
          </YellowButton>
        </a>
      </div>
    </div>
  );
};

type ChooseFileProps = {
  setFile: (file: File) => void;
};

const UploadFileContent = (props: ChooseFileProps) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = event.target.files ?? [];
    if (file) {
      props.setFile(file);
    }
  };

  return (
    <FileUpload
      // @ts-expect-error types not properly declared in apricot
      accept='.csv'
      className='w-100 d-flex justify-content-center align-items-center flex-direction--column'
      label={'Browse for your file'}
      name='staff-file-upload'
      id='staff-file-upload-input'
      btnId='staff-file-upload-btn'
      onFileSelected={handleFileChange}
    />
  );
};

const UploadFileTitle = () => {
  return (
    <div>
      {/* Using upload for now because list-edit isn't working */}
      <div>
        <Icon name='upload' className='p-2' />
        <b>Upload your file.</b>
      </div>
    </div>
  );
};

export function ChooseFile(props: ChooseFileProps) {
  const downloadCsvAccordionData: CardContentRowProps[] = [
    {
      content: <RequirementsContent />,
      contentId: 'requirements',
      headerId: 'requirements-header',
      title: 'Requirements',
    },
    {
      content: <TipsContent />,
      contentId: 'tips',
      headerId: 'tips-header',
      open: true,
      title: 'Tips',
    },
  ];

  return (
    <>
      <CardWithTitle
        titleContent={<DownloadCsvTitle />}
        bodyContent={<CardContentRow items={downloadCsvAccordionData} />}
      />
      <CardWithTitle titleContent={<UploadFileTitle />} bodyContent={<UploadFileContent setFile={props.setFile} />} />
    </>
  );
}
