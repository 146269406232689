import React, { useEffect } from 'react';
import { IStaffEditable } from '../type';
import { CloseButton } from '@cb/apricot-react';
import Select from '../../../../ui/form/Select';

import { canCreateAdmin } from '../../../../../utils/user';
import { OPEN_DRAWER_DELAY_MS } from '../../../../../constants/delay';
import { digitalrolesDisplay, rolesDisplay } from '../../../../../constants/roles';
import { ResizeContext } from '../../../../../context/ResizeContext';
import { useStateValue } from '../../../../../context/AppContext';

import { TooltipInput } from './TooltipInput';

import './AddStaffRowEditable.scss';
import type { Value as E164Number } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import { getDefaultCountry } from '../../../../../utils/phone';
import flags from 'react-phone-number-input/flags';
interface AddStaffRowEditableProps {
  deleteEnabled: boolean;
  deleteStaffHandler: (staff: IStaffEditable) => void;
  formError?: { firstName: string; lastName: string; email: string; phone: string };
  onBlur?: (staff: any, field: string) => void;
  editStaffChangeHandler: (staff: IStaffEditable) => void;
  staff?: IStaffEditable;
}

const defaultStaff: IStaffEditable = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  id: '',
};

export const AddStaffRowEditable = ({
  deleteEnabled = true,
  deleteStaffHandler = () => {},
  editStaffChangeHandler = () => {},
  onBlur = () => {},
  staff = defaultStaff,
  formError = { firstName: '', lastName: '', email: '', phone: '' },
}: AddStaffRowEditableProps) => {
  const windowSize = React.useContext(ResizeContext);
  const { orgEvent, user } = useStateValue();
  const rolesDisplayLocal = orgEvent?.dapInd ? digitalrolesDisplay : rolesDisplay;

  const UI_PREFIX = 'addStaffTable';

  useEffect(() => {
    setTimeout(() => {
      //Delay so Apricot Select does not show ellipsis on initial render, when drawer is opening.
      editStaffChangeHandler({ ...staff, role: staff.role });
    }, OPEN_DRAWER_DELAY_MS);
  }, []);

  function generateRoleOptions() {
    let displayItem;
    const displayRoles: { label: string; value: string }[] = [];

    rolesDisplayLocal.forEach((item) => {
      displayItem = {
        label: item.title,
        value: item.value,
      };

      if (!item.requireAdmin) {
        displayRoles.push(displayItem);
      } else if (canCreateAdmin(orgEvent, user)) {
        displayRoles.push(displayItem);
      }
    });

    return displayRoles;
  }

  const roleOptions = generateRoleOptions();
  function renderDesktopComponent() {
    return (
      <tr>
        <td className='staff-checkbox'>
          <CloseButton
            data-automation='button-delete-staff'
            disabled={!deleteEnabled}
            id={`${UI_PREFIX}-delete-row-${staff.id}`}
            icon='trash'
            onClick={() => deleteStaffHandler(staff)}
            iconDecorative
            greyscale={!deleteEnabled}
            type='button'
            aria-label={`Delete row ${staff.id}`}
          />
        </td>
        <td>
          <TooltipInput
            inputId={`staff-first-name-${staff.id}`}
            inputMaxLength={100}
            inputClassName='staff-name-field'
            inputValue={staff.firstName}
            onChange={(val) => {
              const staffCopy = { ...staff, firstName: val.target.value };
              editStaffChangeHandler(staffCopy);
            }}
            onBlur={() => onBlur(staff, 'firstName')}
            inputAriaLabel='Enter the first name of staff member'
            tooltipId={`tooltip-staff-first-name-${staff.id}`}
            tooltipMsg={staff.firstName}
            errorMessage={formError.firstName}
          ></TooltipInput>
        </td>
        <td>
          <TooltipInput
            inputId={`staff-last-name-${staff.id}`}
            inputMaxLength={100}
            inputClassName='staff-name-field'
            inputValue={staff.lastName}
            onChange={(val) => {
              const staffCopy = { ...staff, lastName: val.target.value };
              editStaffChangeHandler(staffCopy);
            }}
            onBlur={() => onBlur(staff, 'lastName')}
            inputAriaLabel='Enter the last name of staff member'
            tooltipId={`tooltip-staff-last-name-${staff.id}`}
            tooltipMsg={staff.lastName}
            errorMessage={formError.lastName}
          ></TooltipInput>
        </td>
        <td>
          <TooltipInput
            inputId={`staff-email-${staff.id}`}
            inputMaxLength={254}
            inputValue={staff.email}
            inputClassName='staff-email-field' //This is a placeholder className to calculate when tooltip should appear
            onChange={(val) => {
              const staffCopy = { ...staff, email: val.target.value };
              editStaffChangeHandler(staffCopy);
            }}
            onBlur={() => onBlur(staff, 'email')}
            inputAriaLabel='Enter the email address of staff member'
            tooltipId={`tooltip-staff-email-${staff.id}`}
            tooltipMsg={staff.email}
            errorMessage={formError.email}
          ></TooltipInput>
        </td>
        <td>
          <PhoneInput
            className='cb-input'
            autoComplete='off'
            defaultCountry={getDefaultCountry(orgEvent.isoCountryCode)}
            displayInitialValueAsLocalNumber
            flags={flags}
            id={`staff-phone-${staff.id}`}
            name={`${UI_PREFIX}-phoneMobile`}
            onChange={(val: E164Number) => {
              const staffCopy = { ...staff, phone: val };
              editStaffChangeHandler(staffCopy);
            }}
            onBlur={() => onBlur(staff, 'phone')}
            smartCaret={true}
            value={staff.phone as E164Number}
          />
          {formError.phone && (
            <p
              className='cb-input-helper cb-validation-error'
              id={`staff-phone-${staff.id}-error`}
              role='alert'
              aria-atomic='true'
            >
              {formError.phone}
            </p>
          )}
        </td>
        <td id={'role-select-wrapper'}>
          <Select
            data-automation='Bulk-Add-staff-role'
            floating={false}
            id={`staff-role-${staff.id}`}
            name={'staff-role'}
            onChange={(val) => {
              const staffCopy = { ...staff, role: val };
              editStaffChangeHandler(staffCopy);
            }}
            value={staff.role}
            // values={Object.keys(roles).map((role) => ({
            //   label: roles[role] || 'Unassigned',
            //   value: role,
            // }))}
            values={roleOptions}
            condensed={true}
          />
        </td>
      </tr>
    );
  }

  return !windowSize.mobile && renderDesktopComponent();
};
