import { uniqBy } from 'lodash';

export function fetchMoreStudents(data) {
  const { lastKey } = data?.paginatedStudents || {};

  return lastKey
    ? {
        variables: {
          input: { startKey: lastKey },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult || !fetchMoreResult.paginatedStudents || !fetchMoreResult.paginatedStudents.students) {
            return {
              paginatedStudents: {
                students: uniqBy([...prev.paginatedStudents.students], 'id'),
                lastKey: null,
              },
            };
          }
          return Object.assign({}, prev, {
            paginatedStudents: {
              students: uniqBy(
                [...prev.paginatedStudents.students, ...fetchMoreResult.paginatedStudents.students],
                'id'
              ),
              lastKey: fetchMoreResult.paginatedStudents.lastKey,
            },
          });
        },
      }
    : undefined;
}
