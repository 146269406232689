import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import isEmpty from 'lodash/isEmpty';
import { formatStudentName } from '../../utils/common';
import { formattedDate } from '../../utils/date';

function Selection({
  additionalInstructions = '',
  closeMenuOnSelect = true,
  elements = [],
  enableFilterOption = false,
  errorMessage = '',
  filterOption = () => {},
  formatOptionLabel,
  handleSelectChange = () => {},
  id = '',
  instanceId = '',
  isMulti = true,
  label = '',
  labelKeys = ['title'],
  name = '',
  noOptionsMessage = () => null,
  optionRenderer = () => {},
  placeholder,
  readOnly = false,
  required = false,
  selectedElements = [],
  srOnlyLabel = false,
  valueKey = 'id',
}) {
  function getOptionLabel(labelKeys) {
    return function OptionLabel(data) {
      // special case for student names -- return formatted name
      if (labelKeys[0] === 'formattedStudentName') {
        return (
          <div id={`${name}-studentName-${data.candRegNo}`} data-automation={`${name}-studentName`}>
            <strong style={{ fontSize: '14px', lineHeight: '16px' }}>{formatStudentName(data)}</strong>
            <br />
            <div style={{ fontSize: '12px', lineHeight: '14px' }}>
              DOB: {data?.candDOB ? formattedDate(data.candDOB) : '-'}
            </div>
          </div>
        );
      } else {
        // Loop through available labelKeys and concat if needed.
        return labelKeys
          .filter((option) => data[option]) // filter out empty options
          .map((option) => data[option])
          .join(', ');
      }
    };
  }

  function getOptionValue(valueKey) {
    return function (data) {
      return data[valueKey];
    };
  }

  const styleOverrides = {
    option: (styles, { isFocused, isSelected }) => {
      return {
        ...styles,
        'backgroundColor': isFocused || isSelected ? '#E6EDF8' : '#FFF',
        'color': isSelected ? 'white' : '#505050',
        'fontFamily': 'Roboto',
        ':hover': {
          cursor: 'pointer',
        },
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: '#F0F0F0',
        border: '1px solid #D9D9D9',
        borderRadius: '8px',
        fontFamily: 'Roboto',
        fontSize: '14px',
        padding: '2px',
      };
    },
    multiValueRemove: (styles) => {
      return {
        ...styles,
        ':hover': {
          backgroundColor: '#B2B2B2',
          cursor: 'pointer',
        },
      };
    },
  };

  return (
    <div className='tdtk-form-group' id={`${id}-wrapper`} tabIndex={-1}>
      {label ? (
        <label
          id={`${name}-label`}
          className={`${srOnlyLabel ? 'cb-sr-only' : ''} control-label ${required ? 'cb-required' : ''}`}
          htmlFor={name}
          style={{ fontWeight: 'bold' }}
        >
          {label}
        </label>
      ) : null}
      {!isEmpty(additionalInstructions) ? (
        <p className='mx-0 my-2 cb-red-color' id={`${name}-additionalLabel`}>
          <b>{additionalInstructions}</b>
        </p>
      ) : null}
      <ReactSelect
        aria-describedby={errorMessage ? `${name}-error` : ''}
        aria-invalid={errorMessage ? 'true' : 'false'}
        aria-labelledby={`${name}-label ${additionalInstructions ? `${name}-additionalLabel` : ''}`}
        className='react-select--custom border__rounded-full'
        closeMenuOnSelect={closeMenuOnSelect}
        defaultValue={selectedElements}
        filterOption={enableFilterOption ? filterOption : null}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={optionRenderer(labelKeys) || getOptionLabel(labelKeys)}
        getOptionValue={getOptionValue(valueKey)}
        id={id}
        instanceId={instanceId}
        openMenuOnClick={true}
        openMenuOnFocus={true}
        isClearable={!readOnly}
        isDisabled={readOnly}
        isMulti={isMulti}
        isSearchable={!readOnly}
        name={name}
        noOptionsMessage={noOptionsMessage}
        onChange={handleSelectChange(id)}
        options={elements}
        placeholder={placeholder || 'Select from the list or search'}
        styles={styleOverrides}
      />
      {errorMessage && (
        <p className='mb-4 cb-input-helper cb-validation-error' id={`${name}-error`}>
          {errorMessage}
        </p>
      )}
    </div>
  );
}

Selection.propTypes = {
  additionalInstructions: PropTypes.string,
  closeMenuOnSelect: PropTypes.bool,
  elements: PropTypes.array,
  enableFilterOption: PropTypes.bool,
  errorMessage: PropTypes.string,
  filterOption: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  handleSelectChange: PropTypes.func,
  id: PropTypes.string,
  isMulti: PropTypes.bool,
  label: PropTypes.string,
  labelKeys: PropTypes.array,
  name: PropTypes.string,
  noOptionsMessage: PropTypes.func,
  optionRenderer: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  selectedElements: PropTypes.array,
  srOnlyLabel: PropTypes.bool,
  valueKey: PropTypes.string,
};

export default Selection;
