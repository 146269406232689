import React, { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_ROOMS } from '../../../../../apollo/mutations';
import ErrorMessages from '../../../../ui/message/ErrorMessage';
import { messages as updateMessages, rules as updateRules } from '../../../../../validations/room/add';
import { buildNetworkErrorMessages, buildValidationErrorMessages } from '../../../../../validations/common';
import { localStateReducer, scrollTo } from '../../../../../utils/common';
import { ModalDispatchContext } from '../../../../ui/modal/ModalContext';
import Input from '../../../../ui/form/Input';
import OneButtonModal from '../../../../ui/modal/standard/OneButtonModal';
import { Icon, CloseButton, PrimaryButton, YellowButton } from '@cb/apricot-react';
import { validate, validateAll } from 'indicative';
import { Room } from '../types';

import './BulkAddRooms.scss';
import '../../../common/common.scss';

import { useNavigate } from 'react-router-dom';

export interface BulkAddRoomsProps {
  initialRows: number;
  onClose: () => void;
  handleRoomChanges: (currentRooms: { title: string; capacity: string }[]) => void;
  handleDrawerDirty: (isDirty: boolean) => void;
  rooms: { title: string; capacity: string }[];
  footerButtonLabel: string;
  type?: 'create' | 'import';
  enableResetBtn: boolean;
}

function BulkAddRooms({
  initialRows = 1,
  onClose = () => {},
  handleDrawerDirty = (isDirty) => {},
  rooms = [],
  handleRoomChanges = (currentRooms) => {},
  footerButtonLabel,
  type = 'create',
  enableResetBtn,
}: BulkAddRoomsProps) {
  const navigate = useNavigate();

  function deepCopy(obj = {}) {
    return JSON.parse(JSON.stringify(obj));
  }

  function handleAddRow(e) {
    e && e.preventDefault && e.preventDefault();

    // Deep copy form objects.
    const form = deepCopy(localState.form);
    const formErrors = deepCopy(localState.formErrors);

    form.push({ ...formFields });

    formErrors.push({ ...formFields });

    handleRoomChanges(form);
    setLocalState({
      form,
      formErrors,
    });
  }

  function handleDelete(index = 0) {
    return function (e) {
      e && e.preventDefault && e.preventDefault();

      let capacityTotal = 0;
      let roomTotal = 0;

      // Deep copy form objects.
      const form = deepCopy(localState.form);
      const formErrors = deepCopy(localState.formErrors);

      if (form.length > 1) {
        form.splice(index, 1);
        formErrors.splice(index, 1);
      }

      // Get the capacity total.
      form.forEach((row) => {
        if (row.title.trim() !== '' && !isNaN(parseInt(row.capacity, 10))) {
          capacityTotal += parseInt(row.capacity, 10);
          roomTotal++;
        }
      });

      handleRoomChanges(form);
      setLocalState({
        capacityTotal,
        form,
        formErrors,
        isDirty: roomTotal > 0 && capacityTotal > 0,
        roomTotal,
        submitEnabled: roomTotal > 0 && capacityTotal > 0,
      });
    };
  }

  function generateForm() {
    // Check out the imported data
    return localState.form.map((formRow, index) => (
      <tr key={index}>
        <td
          style={{
            verticalAlign: 'middle',
            textAlign: 'center',
          }}
        >
          <CloseButton
            id={`${UI_PREFIX}-delete-row-${index}`}
            icon='trash'
            disabled={localState.form.length <= 1}
            onClick={handleDelete(index)}
            iconDecorative
            greyscale={localState.form.length <= 1}
            type='button'
            aria-label={`Delete row ${index}`}
          />
        </td>
        <td
          style={{
            width: '50%',
            verticalAlign: 'middle',
          }}
        >
          <Input
            errorMessage={localState.formErrors[index] ? localState.formErrors[index].title : ''}
            id={`${UI_PREFIX}-title-${index}`}
            label='Room Name'
            labelClassName='cb-sr-only'
            maxLength={254}
            name={`${UI_PREFIX}-title-${index}`}
            onChange={changeFormState(index)}
            required={true}
            value={formRow.title ? formRow.title : ''}
            ariaLabel={`Enter the name of the room ${index}`}
          />
        </td>
        <td
          style={{
            width: '40%',
            verticalAlign: 'middle',
          }}
        >
          <Input
            errorMessage={localState.formErrors[index] ? localState.formErrors[index].capacity : ''}
            id={`${UI_PREFIX}-capacity-${index}`}
            label='Capacity'
            labelClassName='cb-sr-only'
            maxLength={4}
            name={`${UI_PREFIX}-capacity-${index}`}
            onChange={changeFormState(index)}
            required={true}
            value={formRow.capacity ? formRow.capacity.toString() : ''}
            ariaLabel={`Enter the capacity of the room ${index}`}
          />
        </td>
      </tr>
    ));
  }

  function changeFormState(index = 0) {
    return async function (e) {
      const name = e.target.name;
      const value = e.target.value;
      let capacityTotal = 0;
      let roomTotal = 0;

      // Deep copy objects in array.
      const form = deepCopy(localState.form);
      const formErrors = deepCopy(localState.formErrors);
      let submitEnabled = true;

      // Strip out form prefix.
      const simpleFieldName = name.replace(`${UI_PREFIX}-`, '').replace(`-${index}`, '');
      const newObj = {
        [simpleFieldName]: value,
      };

      // Reset error validation till we get a new error.
      formErrors[index][simpleFieldName] = '';

      form[index][simpleFieldName] = value;

      // Validate this field if it has any rules.
      if (updateRules[simpleFieldName] !== undefined) {
        await validate(newObj, { [simpleFieldName]: updateRules[simpleFieldName] }, updateMessages).catch((errors) => {
          errors.forEach((err) => {
            formErrors[index][simpleFieldName] = err.message;
            submitEnabled = false;
          });
        });
      }

      // Get the capacity total.
      form.forEach((row) => {
        if (row.title.trim() !== '' && !isNaN(parseInt(row.capacity, 10))) {
          capacityTotal += parseInt(row.capacity, 10);
          roomTotal++;
        }
      });

      handleDrawerDirty(true);
      handleRoomChanges(form);
      setLocalState({
        capacityTotal,
        form,
        isDirty: capacityTotal > 0,
        formErrors,
        roomTotal,
        submitEnabled: submitEnabled && roomTotal > 0 && capacityTotal > 0,
      });
    };
  }

  function addNewRooms(finalForm = []) {
    const input = {
      source: type,
      rooms: finalForm,
    };
    createRooms({
      variables: { input },
      refetchQueries: ['getSiteStats', 'getOnlyRooms'],
    })
      .then(() => {
        onClose();

        // Confirm rooms added successfully.
        dispatchModal(
          <OneButtonModal
            onClose={() => {
              scrollTo('add-room');
              navigate('/rooms');
            }}
            buttonLabel='Close'
            title={finalForm.length > 1 ? 'You’ve added rooms successfully.' : 'You’ve added a room successfully.'}
            modalId='confirm-room-add-successfully'
            variant='success'
          />
        );
      })
      .catch((errors) => {
        scrollTo(UI_PREFIX, 'add-rooms-side-drawer');
        handleDrawerDirty(true);
        let submitErrors;

        if (Array.isArray(errors.graphQLErrors) && errors.graphQLErrors.length > 0) {
          submitErrors = buildValidationErrorMessages(errors.graphQLErrors, UI_PREFIX, updateMessages);
          submitErrors = submitErrors.map((err) => {
            if (err && err.errorMessage === updateMessages['title.unique'] && err.key) {
              const key_fields = err.key.split('-');
              const index = parseInt(key_fields[key_fields.length - 1], 10);
              err.errorMessage = finalForm[index]?.title
                ? `This room: ${finalForm[index]?.title} is already in use.`
                : err.errorMessage;
            }
            return err;
          });
          setLocalState({ submitErrors });
        } else if (Array.isArray(errors.networkError.result.errors) && errors.networkError.result.errors.length > 0) {
          submitErrors = buildNetworkErrorMessages(errors.networkError.result.errors, UI_PREFIX, updateMessages);
          setLocalState({ submitErrors });
        }

        setLocalState({
          isProcessing: false,
          submitEnabled: false,
          submitErrors,
        });
      });
  }

  async function validateTheForm(form) {
    const { capacityTotal } = localState;
    const formErrors: Room[] = [];
    let submitEnabled = true;
    const namesArr = [];

    const catchCallback = (index: number) => (errors) => {
      submitEnabled = false;
      errors.forEach((err) => {
        formErrors[index][err.field] = err.message;
      });
    };

    for (let index = 0, len = form.length; index < len; index++) {
      // Reset this row's validation first and only set if there's an issue.
      formErrors.push({ ...formFields });

      // If they filled out one or the other field, validate both.
      if (form[index] && (form[index].title || form[index].capacity)) {
        // Push the name for this room to a temporary array to detect duplicates.
        if (namesArr.indexOf(form[index].title.toLowerCase()) === -1) {
          // We don't have this, add it to the array.
          namesArr.push(form[index].title.toLowerCase());
        } else {
          submitEnabled = false;
          formErrors[index]['title'] = 'This room name is already in use.';
        }

        // Validate this row.
        await validateAll(form[index], updateRules, updateMessages).catch(catchCallback(index));
      }
    }
    handleRoomChanges(form);
    return {
      form,
      formErrors,
      isDirty: capacityTotal > 0,
      submitEnabled: !localState.isProcessing && submitEnabled,
    };
  }

  async function processForm() {
    let capacity = '';
    const finalForm = [];
    let hasErrors = false;
    let title = '';

    // Clear any empty rows where they didn't fill anything out.
    localState.form.forEach((formRow) => {
      capacity = !isNaN(parseInt(formRow.capacity, 10)) ? parseInt(formRow.capacity, 10) : 0;
      title = formRow.title ? formRow.title.trim() : '';

      if (capacity || title) {
        // Convert Capacity to int.
        finalForm.push({
          capacity,
          title,
        });
      }
    });

    // Ensure we have at least 1 row.
    if (finalForm.length > 0) {
      // Validate the form one last time.
      const { form, formErrors, isDirty, submitEnabled } = await validateTheForm(finalForm);

      // If we have any client errors, find the first one and scroll to it.
      for (let formErrIndex = 0, formErrLen = formErrors.length; formErrIndex < formErrLen; formErrIndex++) {
        if (formErrors[formErrIndex]?.title) {
          hasErrors = true;
          scrollTo(`${UI_PREFIX}-title-${formErrIndex}`, 'add-rooms-side-drawer');
          break;
        } else if (formErrors[formErrIndex]?.capacity) {
          hasErrors = true;
          scrollTo(`${UI_PREFIX}-capacity-${formErrIndex}`, 'add-rooms-side-drawer');
          break;
        }
      }

      // Check if we're good to go.
      if (!hasErrors) {
        // Submit the form to GraphQL!
        setLocalState({ form });
        addNewRooms(finalForm);
      } else {
        setLocalState({
          isProcessing: false,
          form,
          formErrors,
          isDirty,
          submitEnabled,
        });
      }
    }
  }

  function handleSubmit(e) {
    e && e.preventDefault && e.preventDefault();

    // reset drawerDirty to false
    handleDrawerDirty(false);

    if (localState.submitEnabled && !localState.isProcessing) {
      setLocalState({
        isProcessing: true,
        submitEnabled: false,
      });

      processForm();
    }
  }

  const UI_PREFIX = 'bulk-add-room-form';

  // Global App state.
  const dispatchModal = useContext(ModalDispatchContext);

  const formFields = {
    capacity: '',
    title: '',
  };

  useEffect(() => {
    const resetForm = [];
    const resetFormErrors = [];

    if (rooms && rooms.length > 0) {
      for (let i = 0; i < rooms.length; i++) {
        resetForm.push({ ...rooms[i] });
        resetFormErrors.push({ ...formFields });
      }
      handleDrawerDirty(true);
    } else {
      for (let i = 0; i < initialRows; i++) {
        resetForm.push({ ...formFields });
        resetFormErrors.push({ ...formFields });
      }
    }
    setLocalState({
      form: resetForm,
      formErrors: resetFormErrors,
      submitEnabled: rooms.length > 0,
      capacityTotal: rooms.reduce((acc, room) => acc + parseInt(room.capacity, 10), 0),
      roomTotal: rooms.length,
    });
  }, [rooms]);
  // Prepare the initial form, using the number of initial rows to populate it.
  const initialForm = [];
  const initialFormErrors = [];

  // Populate the initial form with fields that can be filled later.

  if (rooms && rooms.length > 0) {
    for (let i = 0; i < rooms.length; i++) {
      initialForm.push({ ...rooms[i] });
      initialFormErrors.push({ ...formFields });
    }
  } else {
    for (let i = 0; i < initialRows; i++) {
      initialForm.push({ ...formFields });
      initialFormErrors.push({ ...formFields });
    }
  }

  // Local state.
  const [localState, setLocalState] = React.useReducer(localStateReducer, {
    capacityTotal: 0,
    form: initialForm,
    formErrors: initialFormErrors,
    importError: '',
    isDirty: false,
    isProcessing: false,
    roomTotal: 0,
    selectedPastEvent: '',
    submitEnabled: false,
    submitErrors: [],
  });

  useEffect(() => {
    setLocalState({ submitErrors: [] });
  }, [enableResetBtn]);
  // Apollo.
  const [createRooms] = useMutation(CREATE_ROOMS);

  return (
    <form className='cb-form' id={UI_PREFIX}>
      <div className={`row sticky-wrapper m-0 p-2 form-content ${type === 'import' ? 'form-content-import' : ''}`}>
        {!(type === 'import' && rooms.length === 0) && (
          <>
            <div className='col-md-12'>
              {/* Navigate/scroll the view to the errorMessages component and then put focus */}
              <ErrorMessages errorMessages={localState.submitErrors} scrollableWindowId='add-rooms-side-drawer' />

              <p className='mb-4'>* = Required</p>
              <table className='cb-table cb-input-default table-sticky--no-nav' id='room-form-table'>
                <thead>
                  <tr>
                    <th id='roomAction' className='sticky'>
                      <span className='cb-sr-only'> Action</span>
                    </th>
                    <th id='roomName' className='sticky'>
                      Room Name<sup>*</sup>
                    </th>
                    <th id='roomCapacity' className='sticky'>
                      Capacity<sup>*</sup>
                    </th>
                  </tr>
                </thead>
                <tbody key={`bulk-add-rooms-form-${localState.form.length}`}>{generateForm()}</tbody>
              </table>
            </div>
            <div className='display-flex my-4 form-add-row'>
              <PrimaryButton data-automation='button-add-row' onClick={handleAddRow} small>
                <Icon name='plus' size='12' style={{ paddingRight: '5px' }} />
                Add Row
              </PrimaryButton>
            </div>{' '}
          </>
        )}
      </div>
      <div className='display-flex form-footer'>
        <p
          data-automation='import-rooms-total'
          aria-atomic='true'
          aria-live='polite'
          className='cb-roboto-bold py-2 ml-4'
        >
          Total: {localState.capacityTotal} seat{`${localState.capacityTotal === 1 ? '' : 's'}`} in{' '}
          {localState.roomTotal} room{`${localState.roomTotal === 1 ? '' : 's'}`}
        </p>
        <YellowButton
          small
          disabled={!localState.submitEnabled}
          data-automation={'button-add-room-form-confirm'}
          onClick={handleSubmit}
          className='flex--justify-right ml-auto mr-4'
        >
          {footerButtonLabel}
        </YellowButton>
      </div>
    </form>
  );
}

export default BulkAddRooms;
